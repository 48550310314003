import { Injectable, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApplicationInsightsService, RunService, SiteHeaderService } from '@wtw/platform/services';
import { ScenarioProxy, CompanyDataStatus, RunViewModel, FinancialData, ReferenceDataProxy, FinancialSettingData, ScenarioData } from 'app/api/dtos';
import { RunInfo, CurrencyInfo, RunModel } from '@wtw/platform/api/dtos';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Proxy } from "@wtw/platform/api";


@Injectable()
export class FinancialService {

    public tabHeaders: { key: string, selected: boolean }[] = [];
    public showCompanyType = true;
    public tabDictionary = {};
    public defaultProjections: any[];
    public form;
    public settingsForm;
    public historicalDates: string[] = [];
    public projectionDates: string[] = [];
    public financialStatement: any;
    public savedStatements;
    public validStatements;
    public savedStatementsLoaded;
    public referenceData;
    public loadingReferenceData: boolean;
    public run: RunViewModel;


    public historical: FinancialData[];
    public historicalSnapshot: FinancialData[];
    public projection: FinancialData[];
    public projectionSnapshot: FinancialData[];
    public isCalculationCompletedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isCalculationCompleted$ = this.isCalculationCompletedSubject.asObservable();
    private _runService: RunService;
    private _scenarioServiceProxy: ScenarioProxy;
    private _referenceDataProxy: ReferenceDataProxy;
    private _appInsightService: ApplicationInsightsService;
    private fb: FormBuilder;
    private runInfo: RunInfo;
    private currencyInfo: CurrencyInfo;
    private $generateFinancialsObservable = new Observable<boolean>();
    private loadingInProgress: boolean;
    private _translate: TranslateService;
    private _headerService: SiteHeaderService;
    private _runsProxy: Proxy.RunsProxy;
    private uniqueId = 0;
    private estimateTrends: any;
    private meanTrendId = 100173;
    private highTrendId = 100175;
    private lowTrendId = 100176;
    private trendTypeDict = {
        1: '/assets/ico_bad_01.svg',
        2: undefined,
        3: '/assets/ico_good_01.svg',
    };

    constructor(private inj: Injector, private _sanitizer: DomSanitizer) {

    }

    public setCalculationCompleted(value: boolean): void {
        this.isCalculationCompletedSubject.next(!this.loadingInProgress);
            }

    init() {
        setTimeout(() => {
            this._runService = this.inj.get(RunService);
            this._scenarioServiceProxy = this.inj.get(ScenarioProxy);
            this.fb = this.inj.get(FormBuilder);
            this._translate = this.inj.get(TranslateService);
            this._referenceDataProxy = this.inj.get(ReferenceDataProxy);
            this._appInsightService = this.inj.get(ApplicationInsightsService);
            this._headerService = this.inj.get(SiteHeaderService);
            this._runsProxy=this.inj.get(Proxy.RunsProxy);

            this._runService.currencyConverted.pipe(switchMap(c => {
                return this._scenarioServiceProxy.
                    updateCurrency(c.runId, c.currencyInfo.currentSelectedCurrency).uiSignal('Loading')
                    .pipe(map(_ => c));
            })).subscribe(run => {
                this._runService.persist(run.runId, run.data, run.currencyInfo, null, null, null, true)
                    .subscribe(d => {
                        this.GetFinancialData();
                    });
            });
        });
    }

    reloadRun(run: RunModel) {
        this.setCalculationCompleted(false);
        if (!this.loadingInProgress) {
            this.runInfo = run.info;
            this.currencyInfo = run.currencyInfo;
            this.run = run.data;
            this.showCompanyType = true;
            this.savedStatementsLoaded = false;
            this.getReferenceData();
            if (this.run.financialInput.selectedScenarioId) {
                this.getSavedScenarios();
                this.getValidScenarios();
                this.GetFinancialData();
            } else {
                this.loadingInProgress = true;
                this.currencyInfo.currentSelectedCurrency = this.run.generalClientInfo.defaultCurrencyCode;
                this.GenerateDefaultFinancials(this.run.generalClientInfo.dataStatus);
            }

            this.setCalculationCompleted(true);
        }
    }

    public Load(fb: FormBuilder) {
        this.LoadFinancial();
        this.form = this.SetFinancialForm(fb);
    }

    public LoadFinancial() {
        this.NormaliseCurrencyFields(this.financialStatement.historicalData, 1e-6);
        this.NormaliseCurrencyFields(this.financialStatement.projectionData, 1e-6);
        this.historical = this.financialStatement.historicalData;
        this.projection = this.financialStatement.projectionData;
        this.historicalDates.length = 0;
        this.historicalDates.push(...this.GetHistoricalDates());
        this.projectionDates.length = 0;
        this.projectionDates.push(...this.GetProjectionDates());
        this.tabHeaders.length = 0;
        this.tabHeaders.push(...this.GetFinancialTabs());
    }

    public getSavedScenarios() {
        this._scenarioServiceProxy.savedScenarios(this.runInfo.runId).uiSignal('Loading').subscribe(d => {
            this.savedStatementsLoaded = true;
            this.savedStatements = d.data;
            this.setCalculationCompleted(true);
        });
    }

    public getValidScenarios() {
        this._scenarioServiceProxy.validScenarios(this.runInfo.runId).uiSignal('Loading').subscribe(d => {
            this.validStatements = d.data;
        });
        this.setCalculationCompleted(true);
    }

    public getReferenceData() {
        this.loadingReferenceData = true;
        this._referenceDataProxy.financialSettings(this.runInfo.clientId, this.run.generalClientInfo.dataStatus.dataStatus).subscribe(d => {
            this.referenceData = d.data;
            this.loadingReferenceData = false;
        });
    }

    public getTotalRenenueDataItemId() {
        if (!this.financialStatement) {
            return 0;
        }
        const scenarioData = this.financialStatement as ScenarioData;
        if (this.referenceData) {
            return (this.referenceData as FinancialSettingData).totalRevenueHandleItems.find(d => d.reportingTemplateTypeId ===
                scenarioData.finHistorical.reportingTemplateTypeId).dataItemId;
        }
        return 0;
    }

    public GetFinancialData() {
        this._scenarioServiceProxy.scenarioData(this.run.financialInput.selectedScenarioId)
            .uiSignal({ uiLabel: 'Loading', debugInfo: '' }).subscribe(e => {
                this.financialStatement = e.data;
                this.historicalSnapshot = undefined;
                this.projectionSnapshot = undefined;
                this.Load(this.fb);
                this.showCompanyType = !this.run.generalClientInfo.dataStatus.statementCreated;
            });
    }

    public NormaliseCurrencyFields(dataFields: FinancialData[], multiplier: number) {
        dataFields.forEach(c => {
            if (c.scalesFlag && c.dataItemValue) {
                c.dataItemValue *= multiplier;
            }
        }
        );
    }

    public loadScenario(scenarioId) {
        this.run.financialInput.selectedScenarioId = scenarioId;
        this._runService.persist(this.runInfo.runId, this.run, this.currencyInfo, null, null, null, true);
    }

    public setSettingsForm() {

        this.settingsForm = Object.toFormGroup(this.financialStatement.finProjection, this.fb);
        if (this.settingsForm.controls.projectionValue.value) {
            this.settingsForm.controls.projectionValue.setValue(this.settingsForm.controls.projectionValue.value * 100);
        }
    }

    public getCompanyType() {
        return (<RunViewModel>this.run).generalClientInfo.dataStatus;
    }

    getCompanyName(): any {
        return this.run.generalClientInfo.clientName[this.run.generalClientInfo.clientName.length - 1] === '.' ?
            this.run.generalClientInfo.clientName.slice(0, -1) :
            this.run.generalClientInfo.clientName;
    }

    public HideCompanyTypeView() {
        if (this.savedStatementsLoaded === false) {
            this.getSavedScenarios();
        }
        this.run.generalClientInfo.dataStatus.statementCreated = true;
        if (this.loadingInProgress) {
            this.$generateFinancialsObservable = of(false).uiSignal({
                debugInfo: 'execute algorithm T1',
                uiLabel: this._translate.instant('GLOBAL.BUSY.EXECUTING')
            });
        } else {
            this._runService.persist(this.runInfo.runId, this.run, this.currencyInfo).subscribe(d => {
                this.GetFinancialData();
            });
        }
    }

    public GenerateDefaultFinancials(companyDataStatus: CompanyDataStatus) {
        this.setCalculationCompleted(false);
        if (companyDataStatus.dataStatus === 3) {
            this._scenarioServiceProxy.createPrivateCompanyFinancial(this.runInfo.runId, this.run.generalClientInfo.clientId)
                .subscribe(c => {
                    this.run.financialInput.selectedScenarioId = c.data;
                    this._runService.executeAlgorithmAsync(
                        this.runInfo.runId, this.run, 'recommended_applications', this.currencyInfo, undefined, true
                    ).subscribe(d => {
                        d.runModel.data.financialInput.selectedScenarioId = c.data;
                        if (this.loadingInProgress) {
                            this.$generateFinancialsObservable.subscribe(_ => null).unsubscribe();
                        }
                        this.loadingInProgress = false;
                        this.reloadRun(d.runModel);
                    });
                });
        } else {
            this._runService
                .executeAlgorithmAsync(this.runInfo.runId, this.run, 'create_financial_scenario', this.currencyInfo, undefined, true)
                .subscribe(c => {
                    const selectedStatementId = c.runModel.data.financialInput.selectedScenarioId;
                    this.run.financialInput.selectedScenarioId = c.runModel.data.financialInput.selectedScenarioId;
                    this._runService.executeAlgorithmAsync(
                        this.runInfo.runId, this.run, 'recommended_applications', this.currencyInfo, undefined, true
                    ).subscribe(d => {
                        if (this.loadingInProgress) {
                            this.$generateFinancialsObservable.subscribe(_ => null).unsubscribe();
                        }
                        d.runModel.data.financialInput.selectedScenarioId = selectedStatementId;
                        this.loadingInProgress = false;
                        this.reloadRun(d.runModel);
                    });
                });
        }
    }

    public calltrigger(triggerName) {
        this._runService
            .executeAlgorithmAsync(this.runInfo.runId, this.run, triggerName, this.currencyInfo)
            .uiSignal({ debugInfo: 'Calling Trigger', uiLabel: this._translate.instant('GLOBAL.BUSY.LOADING') })
            .subscribe(x=>{
                if  (this.runInfo.pages) {
                    this.runInfo.pages.riskTolerance.canNavigate = this.run.enableRiskTolerance;
                }
                this._runsProxy.updateRunJson(this.runInfo.runId, {
                    runId: this.runInfo.runId,
                    runModelData: this.run,
                    processAction: null,
                    currencyInfo: this.currencyInfo,
                    pages: this.runInfo.pages
                })
                .uiSignal({ debugInfo: 'Calling Trigger', uiLabel: this._translate.instant('GLOBAL.BUSY.LOADING') })
                .subscribe();  
            });

    }

    public callRiskToleranceTrigger() {
        this._runService
            .executeAlgorithmAsync(this.runInfo.runId, this.run, 'calc_peer_analysis', this.currencyInfo)
            .uiSignal({ debugInfo: 'Calling Trigger', uiLabel: this._translate.instant('GLOBAL.BUSY.LOADING') })
            .subscribe(c => {
                this._runsProxy
                .updateRunJson(this.runInfo.runId, {
                    runId: this.runInfo.runId,
                    runModelData: this.run,
                    processAction: null,
                    currencyInfo: this.currencyInfo,
                    pages: this.runInfo.pages
                })
                .uiSignal({ debugInfo: 'Calling Trigger', uiLabel: this._translate.instant('GLOBAL.BUSY.LOADING') })
                .subscribe(c=>{
                    this._appInsightService.send({
                        eventCategory: 'Client - Runs',
                        eventAction: 'RunCompleted',
                        runId: this.runInfo.runId
                    });
                }); 
                this._headerService.navigation.nextClicked.next();
            });
    }

    public GetFinancialHistorical(dataCollectionTypeId: number) {
        const x = this.groupBy(this.historical.filter(c => c.dataCollectionTypeId === dataCollectionTypeId), 'sectionNumber');
        Object.keys(x).forEach(c => {
            x[c].sort((a, b) => a - b);
        });
    }

    public GetFinancialForm() {
        return this.form;
    }
    public SetFinancialForm(fb: FormBuilder) {
        this.GenerateProjectionDefults();
        this.tabDictionary = [];
        // let uniqueId = 0;
        this.historical.forEach((c: any) => {
            if (isNaN(c.uniqueId)) {
                c.uniqueId = this.uniqueId++;
            }
        });
        this.projection.forEach((c: any) => {
            if (isNaN(c.uniqueId)) {
                c.uniqueId = this.uniqueId++;
            }
        });
        if (this.historicalSnapshot === undefined) {
            this.historicalSnapshot = JSON.parse(JSON.stringify(this.historical));
            this.projectionSnapshot = JSON.parse(JSON.stringify(this.projection));
            // this.RoundInputs(this.historicalSnapshot);
            // this.RoundInputs(this.projectionSnapshot);
        }
        return Object.toFormGroup({
            projection: this.GetAllData(this.projection, this.GetProjectionDates()),
            historical: this.GetAllData(this.historical, this.GetHistoricalDates(), false)
        }, fb);
    }

    public GetAllData(obj, dates, empty = true) {
        const x = this.groupBy(obj, 'dataCollectionTypeId');
        this.setEstimateTrends();
        Object.keys(x).forEach(c => {
            const y = this.groupBy(x[c], 'sectionNumber');
            if (!this.tabDictionary[c]) {
                this.tabDictionary[c] = [];
            }
            this.tabDictionary[c] = Object.keys(y).map((d, i) => {
                const z = this.groupBy(y[d], 'dataItemId');
                const dataItemKeys = Object.keys(z).sort((a, b) => z[a][0].lineOrder - z[b][0].lineOrder);
                return {
                    sectionNumber: d,
                    dataItems: dataItemKeys.map((e, j) => {
                        const filteredDataItemValue = z[e].filter(f => dates.indexOf(f.periodEndDate.substring(0, 10)) !== -1)
                            .map(f => f.dataItemValue);
                        let trendMaxValue = Math.max(...filteredDataItemValue) || 0;
                        let trendMinValue = Math.min(...filteredDataItemValue) || 0;
                        if (!empty && this.tabDictionary[c] && this.tabDictionary[c][i]) {
                            if (this.tabDictionary[c][i].dataItems[j] !== null &&
                                this.tabDictionary[c][i].dataItems[j] !== undefined) {
                                    if (this.tabDictionary[c][i].dataItems[j].trendMax > trendMaxValue) {
                                        trendMaxValue = this.tabDictionary[c][i].dataItems[j].trendMax;
                                    }
                                    if (this.tabDictionary[c][i].dataItems[j].trendMin < trendMinValue) {
                                        trendMinValue = this.tabDictionary[c][i].dataItems[j].trendMin;
                                    }
                                }
                        }
                        return {
                            id: e,
                            isBold: z[e][0].isBold,
                            format: `number:${z[e][0].formatPrecision}`,
                            isMandatory: z[e][0].isMandatory,
                            trendMax: trendMaxValue > 0 ? trendMaxValue : 0,
                            trendMin: trendMinValue < 0 ? trendMinValue : 0,
                            trendMinAbs: Math.abs(trendMinValue),
                            revisions: {
                                img: this.estimateTrends[this.financialStatement.finProjection.periodTypeId][e]
                            }
                        };
                    }),
                    footNotes: dataItemKeys.map(e => z[e][0].footnote).filter(e => e),
                };
            });
            Object.keys(y).forEach(d => {
                const z = this.groupBy(y[d], 'dataItemId');
                Object.keys(z).forEach(e => {
                    z[e].sort((a, b) => {
                        const date1 = new Date(a.periodEndDate);
                        const date2 = new Date(b.periodEndDate);
                        return date2.valueOf() - date1.valueOf();
                    });
                });
                y[d] = z;
            });
            x[c] = y;
        }
        );
        return x;
    }


    public ChangeDate(obj, oldDate, newDate) {
        this.SaveFinancialForm();
        obj.forEach(c => {
            if (c.periodEndDate.indexOf(oldDate.substring(0, 10)) !== -1) {
                c.periodEndDate = moment.utc(newDate).format('YYYY-MM-DD') + 'T00:00:00';
            }
        });
    }

    public GetHistoricalDates() {
        return Object.keys(this.groupBy(this.historical, 'periodEndDate')).sort((a, b) => {
            const date1 = new Date(a);
            const date2 = new Date(b);
            return date2.valueOf() - date1.valueOf();
        }
        ).slice(0, 6).map(c => c.substring(0, 10));
    }

    public GetProjectionDates() {
        return Object.keys(this.groupBy(this.projection, 'periodEndDate')).sort((a, b) => {
            const date1 = new Date(a);
            const date2 = new Date(b);
            return date2.valueOf() - date1.valueOf();
        }
        ).slice(0, 2).map(c => c.substring(0, 10));
    }

    public GetFinancialTabs() {
        return [{ key: '0', selected: true }, ...Object.keys(this.groupBy(this.historical, 'dataCollectionTypeId')).sort().map((c, i) => {
            return {
                key: c,
                selected: false
            };
        })];
    }

    groupBy(xs, key) {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    public AddProjection() {
        if (this.GetProjectionDates().length < 2) {
            this.SaveFinancialForm();
            const x = JSON.parse(JSON.stringify(this.projection));
            let currentDate = moment.utc(Math.max(...this.GetProjectionDates().map(c => new Date(c).valueOf())));
            if (this.financialStatement.finProjection.periodTypeId === 1) {
                currentDate.add(1, 'years');
            } else {
                currentDate = currentDate.add(3, 'months');  // getMonth is 0 based
            }
            x.forEach(c => {
                c.periodEndDate = currentDate.format('YYYY-MM-DD') + 'T00:00:00';
                if (this.financialStatement.finProjection.projectionTypeId === 1) {
                    if (c.dataItemValue && this.financialStatement.finProjection.projectionValue) {
                        c.dataItemValue *= (1 + this.financialStatement.finProjection.projectionValue);
                    }
                } else {
                    c.dataItemValue = '';
                }
            });
            this.projection.push(...x);
        }
    }

    public AddHistorical() {
        if (this.GetHistoricalDates().length < 6) {
            this.SaveFinancialForm();
            const x = JSON.parse(JSON.stringify(this.defaultProjections));
            let currentDate = moment.utc(Math.min(...this.GetHistoricalDates().map(c => new Date(c).valueOf())));
            if (this.financialStatement.finProjection.periodTypeId === 1) {
                currentDate.subtract(1, 'year');
            } else {
                currentDate = currentDate.subtract(3, 'months'); // getMonth is 0 based
            }
            x.forEach(c => c.periodEndDate = currentDate.format('YYYY-MM-DD') + 'T00:00:00');
            this.historical.push(...x);
        }
    }

    public RemoveHistorical(date) {
        this.SaveFinancialForm();
        this.historical.forEach((c, i) => {
            if ((<any>c.periodEndDate).substring(0, 10) === date) {
                this.historical.splice(i, 1);
            }
        });
    }

    public SaveFinancialForm(removeFirstProjection: boolean = false) {
        this.historical.length = 0;
        this.projection.length = 0;
        const rawData = this.form.getRawValue();
        // eslint-disable-next-line guard-for-in
        for (const dataCollectionTypeId in rawData.historical) {
            // eslint-disable-next-line guard-for-in
            for (const section in rawData.historical[dataCollectionTypeId]) {
                // eslint-disable-next-line guard-for-in
                for (const dataItem in rawData.historical[dataCollectionTypeId][section]) {
                    this.historical.push(...rawData.historical[dataCollectionTypeId][section][dataItem]);
                    if (removeFirstProjection) {
                        this.projection.push(rawData.projection[dataCollectionTypeId][section][dataItem][1]);
                    } else {
                        this.projection.push(...rawData.projection[dataCollectionTypeId][section][dataItem]);
                    }
                }
            }
        }
        this.financialStatement.historicalData = this.historical;
        this.financialStatement.projectionData = this.projection;
        this.NormaliseCurrencyFields(this.financialStatement.historicalData, 1e6);
        this.NormaliseCurrencyFields(this.financialStatement.projectionData, 1e6);
    }

    public RemoveProjection() {
        const projectionDates = this.GetProjectionDates();
        if (projectionDates.length === 2) {
            this.SaveFinancialForm(true);
        }
    }

    public sanitizeInput(value: string): any {
        return this._sanitizer.sanitize(1, value).trim();
    }

    public regexPatternValidator(): any {
        return Validators.pattern('^[a-zA-Z0-9 ]+');
    }

    private GenerateProjectionDefults() {
        if (this.historical.length) {
            const date = this.historical[0].periodEndDate;
            this.defaultProjections = JSON.parse(JSON.stringify(this.historical.filter(c => c.periodEndDate === date)));
            this.defaultProjections.forEach(c => {
                c.dataItemValue = null;
                c.periodEndDate = '';
            });
        }
    }

    private setEstimateTrends(): any {
        this.estimateTrends = this.run.estimateTrends.toDictionary('periodType');
        Object.keys(this.estimateTrends).forEach(element => {
            const dataItems = {};
            dataItems[this.meanTrendId] = this.trendTypeDict[this.estimateTrends[element].meanTrend];
            dataItems[this.highTrendId] = this.trendTypeDict[this.estimateTrends[element].highTrend];
            dataItems[this.lowTrendId] = this.trendTypeDict[this.estimateTrends[element].lowTrend];
            this.estimateTrends[element] = dataItems;
        });
    }
}
