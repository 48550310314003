

import { Component, OnInit } from '@angular/core';
import { FinancialService } from '../../../services/financial.service';
import { FormBuilder } from '@angular/forms';
import { ModalService } from '@wtw/platform/services';
import { ChangeSettingsComponent } from './changeSettings/changeSettings.component';
import { TranslateService } from '@ngx-translate/core';
import { LoadFinancialsComponent } from './loadFinancials/loadFinancials.component';
import { SaveFinancialsComponent } from './saveFinancials/saveFinancials.component';
import { ScenarioProxy } from 'app/api/dtos';
import * as moment from 'moment';
import { NumberFormatterPipe } from 'app/pipes/number-formatter.pipe';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';

@Component({
    selector: 'financial-inputs',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/financialInputs.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/financialInputs.component.scss']
})

export class FinancialInputsComponent extends ReactiveComponent implements OnInit {
    public translation: any = {

    };
    public translationKey = 'RTC.FINANCIALS.INPUTS';

    public projectionDates;
    public projectionDatesDatepicker;
    public historicalDates;
    public historicalDatesDatepicker;
    public form: any;
    public collectionId = 0;
    public pageSections: any;
    public settings = {
        defaultProjection: false
    };
    public companyType;
    public settingsTranslation;
    public mandatoryMissing;
    public model;
    public historicalChanged: boolean;
    public unsavedChanges: boolean;
    public monetaryType = 0;

    public tabItemsHistorical =
        [
            {
                key: 'remove',
                displayName: 'Remove',
                translationKey: this.translationKey + '.REMOVEHISTORICAL',
                icon: 'icon-delete ng-star-inserted',
                event: (index) => {
                    this.removeHistorical(this.historicalDates[index]);
                },
                disabled: () => {
                    return this.historicalDates.length === 1;
                }
            },
            {
                key: 'changeDate',
                displayName: 'Change Date',
                translationKey: this.translationKey + '.CHANGEDATE',
                icon: 'icon-ico_edit ng-star-inserted',
                event: (id) => {
                    document.getElementById(`historicalDate${id}`).click();
                },
                disabled: () => false
            }
        ];

    public tabItemsProjection =
        [
            {
                key: 'changeDate',
                displayName: 'Change Date',
                translationKey: this.translationKey + '.CHANGEDATE',
                icon: 'icon-ico_edit ng-star-inserted',
                event: (id) => {
                    document.getElementById(`projectionDate${id}`).click();
                },
                disabled: () => false
            }
        ];

    constructor(private financialService: FinancialService, private fb: FormBuilder, private modalService: ModalService,
        private _translateService: TranslateService, private scenarioProxy: ScenarioProxy,
        private numberFormat: NumberFormatterPipe) {
        super();
    }

    ngOnInit() {
        this.collectionId = 0;
        this.Load();
        this.companyType = this.financialService.getCompanyType().dataStatus;
        if (this.companyType === 1) {
            this.tabItemsHistorical =
                [
                    {
                        key: 'changeDate',
                        displayName: 'Change Date',
                        translationKey: this.translationKey + '.CHANGEDATE',
                        icon: 'icon-ico_edit ng-star-inserted',
                        event: (id) => {
                            document.getElementById(`historicalDate${id}`).click();
                        },
                        disabled: () => false
                    }
                ];
        }
        this.updateTrend(-1, -1);
    }

    pageChanged(collectionId) {
        this.collectionId = collectionId;
        this.pageSections = this.financialService.tabDictionary[collectionId];
    }

    addProjection() {
        if (this.projectionDates.length === 1) {
            this.financialService.AddProjection();
        } else if (this.projectionDates.length === 2) {
            this.financialService.RemoveProjection();
        }
        this.financialService.Load(this.fb);
        this.Load();
        this.financialService.tabHeaders.forEach(c => c.selected = c.key === this.collectionId + '');
        this.updateTrend(-1, -1);
    }

    addHistorical() {
        if (this.historicalDates.length < 6) {
            this.financialService.AddHistorical();
        }
        this.financialService.Load(this.fb);
        this.Load();
        this.financialService.tabHeaders.forEach(c => c.selected = c.key === this.collectionId + '');
        this.updateTrend(-1, -1);
    }

    removeHistorical(date) {
        this.financialService.RemoveHistorical(date);
        this.financialService.Load(this.fb);
        this.Load();
        this.financialService.tabHeaders.forEach(c => c.selected = c.key === this.collectionId + '');
        this.updateTrend(-1, -1);
    }

    updateTrend(sectionNumber, dataItemId) {
        if (sectionNumber !== -1 && dataItemId !== -1) {
            const projectionValues = this.form.controls.projection.value[this.collectionId][sectionNumber][dataItemId]
                .filter(f => this.projectionDates.indexOf(f.periodEndDate.substring(0, 10)) !== -1).map(c => c.dataItemValue);
            const historicalValues = this.form.controls.historical.value[this.collectionId][sectionNumber][dataItemId]
                .filter(f => this.historicalDates.indexOf(f.periodEndDate.substring(0, 10)) !== -1).map(c => c.dataItemValue || 0);
            const mergedValues = projectionValues.concat(historicalValues);
            const currentItem = this.pageSections.find(c => c.sectionNumber === sectionNumber).dataItems.find(c => c.id === dataItemId);

            const trendMinValue = Math.min(...mergedValues) || 0;
            const trendMaxValue = Math.max(...mergedValues) || 0;
            currentItem.trendMin = trendMinValue < 0 ? trendMinValue : 0;
            currentItem.trendMax = trendMaxValue > 0 ? trendMaxValue : 0;
            currentItem.trendMinAbs = Math.abs(currentItem.trendMin);
        }
        const rawData = this.form.getRawValue();
        this.mandatoryMissing = false;
        this.financialService.tabHeaders.forEach(c => {
            (c as any).missingInformation = false;
            for (const section in rawData.historical[c.key]) {
                // eslint-disable-next-line guard-for-in
                for (const dataItem in rawData.historical[c.key][section]) {
                    if (rawData.historical[c.key][section][dataItem]
                        .filter(d => d.dataItemValue !== 0 && !d.dataItemValue && d.isMandatory
                            && this.financialService.historicalDates[0] === d.periodEndDate.substring(0, 10)).length > 0) {
                        (c as any).missingInformation = true;
                        this.mandatoryMissing = true;
                    }
                    // if (rawData.projection[c.key][section][dataItem]
                    //     .filter(d => d.dataItemValue !== 0 && !d.dataItemValue && d.isMandatory
                    //         && this.financialService.projectionDates.find(e => e === d.periodEndDate.substring(0, 10))).length > 0) {
                    //     (c as any).missingInformation = true;
                    //     this.mandatoryMissing = true;
                    // }
                }
            }
        });
        const tempHistorical = [];
        const tempProjection = [];
        for (const dataCollectionTypeId in rawData.historical) {
            // eslint-disable-next-line guard-for-in
            for (const section in rawData.historical[dataCollectionTypeId]) {
                // eslint-disable-next-line guard-for-in
                for (const dataItem in rawData.historical[dataCollectionTypeId][section]) {
                    tempHistorical.push(...rawData.historical[dataCollectionTypeId][section][dataItem]);
                }
                // eslint-disable-next-line guard-for-in
                for (const dataItem in rawData.projection[dataCollectionTypeId][section]) {
                    tempProjection.push(...rawData.projection[dataCollectionTypeId][section][dataItem]);
                }
            }
        }
        this.historicalChanged = false;
        this.unsavedChanges = this.checkForFormChange(tempHistorical, this.financialService.historicalSnapshot);
        if (!this.unsavedChanges) {
            this.unsavedChanges = this.checkForFormChange(tempProjection, this.financialService.projectionSnapshot);
        } else {
            this.historicalChanged = true;
        }
    }

    public menuItemClicked(item, date) {
        item.event(date);
    }

    public openLoadModal() {
        const confirmConfig = {
            class: 'modal-dialog modal-lg'
        };
        this.modalService.confirmWithComponent(LoadFinancialsComponent,null ,confirmConfig).then((res) => {
            if (res.result === true && res.data) {
                this.financialService.run.enableRiskTolerance=false;
                this.financialService.loadScenario(res.data.selectedStatementId);
            }
        });
    }

    public openSavedModal(data?) {
        const confirmConfig = {
            class: 'modal-dialog modal-lg',
            data: data
        };
        this.modalService.confirmWithComponent(SaveFinancialsComponent,null ,confirmConfig).then((res) => {
            if (res.result === true && res.data) {
                this.financialService.run.enableRiskTolerance=false;
                const currentScenarioId = this.financialService.financialStatement.finScenario.finScenarioId;
                if (data) {
                    this.financialService.financialStatement.finProjection = data;
                }
                this.financialService.financialStatement.finScenario.scenarioName = res.data.saveName;
                this.financialService.financialStatement.finScenario.finScenarioId = res.data.selectedStatementId;
                this.financialService.SaveFinancialForm();
                this.scenarioProxy.saveScenario(this.financialService.financialStatement, currentScenarioId)
                    .uiSignal({ debugInfo: 'Saving Statement', uiLabel: 'Saving Statement' }).subscribe(c => {
                        if (c.data.triggerName) {
                            this.financialService.run.financialInput.selectedScenarioId = c.data.scenarioId;
                            this.financialService.calltrigger(c.data.triggerName);
                        } else
                            if (c.data.scenarioId !== currentScenarioId) {
                                this.financialService.loadScenario(c.data.scenarioId);
                            }
                    });
            }
        });
    }

    public changeFinancialDetails() {

        const confirmConfig = {
            class: 'modal-dialog modal-lg'
        };
        this.modalService.confirmWithComponent(ChangeSettingsComponent,null,confirmConfig).then((res) => {
            if (res.result === true && res.data) {
                this.openSavedModal(res.data);
                // this._changeCurrency(res.data.selectedCurrency);
            }
        });
    }

    public runRiskTolerence() {
        this.financialService.run.enableRiskTolerance = true;
        this.financialService.callRiskToleranceTrigger();
    }

    public setProjectionDate(date, index) {
        Promise.resolve(null).then(cc => {
            if (!date || isNaN(index)) {
                return;
            }
            if (this.projectionDates[index] !== moment.utc(date).format('YYYY-MM-DD')) {
                this.financialService.ChangeDate(this.financialService.projection, this.projectionDates[index], date);
                this.financialService.Load(this.fb);
                this.Load();
                this.financialService.tabHeaders.forEach(c => c.selected = c.key === this.collectionId + '');
            }
            this.updateTrend(-1, -1);
        });
    }

    public setHistoricalDate(date, index) {
        Promise.resolve(null).then(cc => {
            if (!date || isNaN(index)) {
                return;
            }
            if (this.historicalDates[index] !== moment.utc(date).format('YYYY-MM-DD')) {
                this.financialService.ChangeDate(this.financialService.historical, this.historicalDates[index], date);
                this.financialService.Load(this.fb);
                this.Load();
                this.financialService.tabHeaders.forEach(c => c.selected = c.key === this.collectionId + '');
            }
            this.updateTrend(-1, -1);
        });
    }

    private checkForFormChange(input1, input2): boolean {
        let a;
        let b;
        let returnValue = false;
        if (input1.length > input2.length) {
            a = input1;
            b = input2;
        } else {
            a = input2;
            b = input1;
        }
        for (const el of a) {
            const x = b
                .find((d: any) => d.uniqueId === el.uniqueId);
            if (!x) {
                returnValue = true;
                break;
            } else {
                if (Math.abs(el.dataItemValue - x.dataItemValue) >= 0.00000001 || el.periodEndDate !== x.periodEndDate) {
                    if (x) {
                        returnValue = true;
                        break;
                    }
                }
            }
        }
        return returnValue;
    }

    private Load() {
        this.historicalDatesDatepicker = this.financialService.historicalDates.map(c => new Date(c));
        this.projectionDatesDatepicker = this.financialService.projectionDates.map(c => new Date(c));
        this.historicalDates = this.financialService.historicalDates;
        this.projectionDates = this.financialService.projectionDates;
        this.pageChanged(this.collectionId);
        this.form = this.financialService.GetFinancialForm();
        this.settings.defaultProjection = this.financialService.financialStatement.finProjection.projectionTypeId === 1;
        const projectionValue = this.numberFormat.transform(this.financialService.financialStatement.finProjection.projectionValue * 100, '2.percentage');
        const projectionPercent = this.settings.defaultProjection ? this._translateService
            .instant('RTC.FINANCIALS.INPUTS.SETTINGDETAILS_PROJECTIONTYPE', { projectionValue: projectionValue }) : '';
        this.settingsTranslation = {
            periodType: this._translateService
                .instant('RTC.FINANCIALS.CHANGESETTINGS.PERIODTYPE' + this.financialService.financialStatement.finProjection.periodTypeId),
            reportingTemplateType: this._translateService
                .instant('RTC.FINANCIALS.CHANGESETTINGS.REPORTINGTEMPLATETYPE' +
                    this.financialService.financialStatement.finProjection.reportingTemplateTypeId),
            accountingStandard: this._translateService
                .instant('RTC.FINANCIALS.CHANGESETTINGS.ACCOUNTINGSTANDARDS'
                    + this.financialService.financialStatement.finProjection.accountingStandardId),
            projectionType: this._translateService
                .instant('RTC.FINANCIALS.CHANGESETTINGS.PROJECTIONTYPES'
                    + this.financialService.financialStatement.finProjection.projectionTypeId),
            projectionValue: projectionPercent,
            companyName: this.financialService.getCompanyName()
        };
    }


}
