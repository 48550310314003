import { RunModel } from '@wtw/platform/api/dtos';
import { RunCloneExtensionPoint } from '@wtw/platform/bootstrap/extension-point.interface';
import { RunService } from '@wtw/platform/services';
import { CloneRunProxy } from 'app/api/generated/CloneRunController';
import { RunViewModel } from 'app/api/dtos';
import { CloneRunOutput } from 'app/api/generated/CloneRunOutput';
import { AppInjector } from './app.injector';

export class CloneRunService extends RunCloneExtensionPoint {
    constructor() {
        super();
    }

    cloneAdditionalRunData?(model: RunModel) {
        const cloneRunProxy = AppInjector.get<CloneRunProxy>(CloneRunProxy);
        const runService = AppInjector.get<RunService>(RunService);
        const viewModel = model.data as RunViewModel;
        cloneRunProxy.cloneRun(viewModel, model.runId).uiSignal('Copying run...').subscribe(ret => {
            const cloned = ret.data as CloneRunOutput;
            if (cloned) {
                viewModel.financialInput.selectedScenarioId = cloned.selectedScenarioId;
                runService.persist(model.runId, viewModel, model.currencyInfo, model.info.stage).subscribe();
            }
        });
    }
}
